<template>
  <el-container style="height: 100%; border: 1px solid #eee">

    <el-aside v-if="isMobile"
        :style="{width: '280px', height: 'auto', backgroundColor: 'rgb(238, 241, 246)',left: (menuShow?'-280px':'-240px')}">
      <el-menu>
        <el-menu-item @click="handleMenuClick()" style="text-align: right; padding-right: 0px;">
          <i class="el-icon-menu"></i>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <el-aside :style="{width: '280px', backgroundColor: 'rgb(238, 241, 246)',left: (menuShow?'0px':'-280px')}">
      <el-menu>
        <el-menu-item v-if="isMobile" index="-1" @click="handleMenuClick()"
                      style=" text-align: right; padding-right: 0px;border-bottom: solid 1px #e6e6e6;">
          <i class="el-icon-close"></i>
        </el-menu-item>

        <el-menu-item index="9999" @click="handleStationClick(null)">
          <i class="el-icon-s-home"></i>
          <span slot="title">{{streetName}}（{{ cameraDataList.length }}）</span>
        </el-menu-item>
        <el-menu-item :index="index" @click="handleStationClick(item)" v-for="(item,index) in treeList" :key="index" v-if="item.stationName != 'Unknow'">
          <i class="el-icon-s-home"></i>
          <span slot="title">{{ item.stationName }}（{{item.children ? item.children.length: 0 }}）</span>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <el-container style="background-color: #FFFFFF">
      <el-main style="padding: 5px 10px;">

        <el-col :span="24">
          <el-row v-if="showAll">
            <el-col>
              <el-button-group style="padding: 5px 10px;">
                <el-button class="avue-dialog__top" @click="playLiveAll">全部播放</el-button>
                <el-button class="avue-dialog__top" @click="stopVideoAll">全部停止</el-button>
              </el-button-group>

            </el-col>
<!--            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <div style="padding: 5px 10px;text-align: right;">
                <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="pageIndex" :page-size="pageSize" :total="totalPage" background layout="prev, pager, next"/>
              </div>
            </el-col>-->
          </el-row>

          <el-row>
            <div v-for="(item,index) in cameraList" :key="index" >
              <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6" style="padding: 5px;">
                <el-card class="card-box-shadow">
                  <div slot="header" class="clearfix">
                    <div>{{item.deviceName}}</div>
                    <div>
                      <el-button-group style="float: right; padding: 3px 0">
                        <el-button class="avue-dialog__top" @click="playLive(item)">播放</el-button>
                        <el-button class="avue-dialog__top" @click="playRecord(item)">回放</el-button>
                        <el-button class="avue-dialog__top" @click="stopVideo(item.deviceSerial)">停止</el-button>
                      </el-button-group>
                    </div>
                  </div>
                  <div :id="'video-container-'+item.deviceSerial" class="video-container" v-show="item.playerVisible">
                  </div>
                  <div class="video-container-holder" v-show="!item.playerVisible">
                    <i class="el-icon el-icon-video-play" @click="playLive(item)"></i>
                  </div>
                </el-card>
              </el-col>
            </div>
          </el-row>

        </el-col>

        <el-dialog :visible.sync="showRecordConfig" title="请选择回放时间">
          <div style="line-height: 3em;">设备：{{ ezvizRecordConfig.deviceName }}</div>
          <div>
            <el-date-picker
                v-model="ezvizRecordConfig.datePick"
                type="datetimerange"
                size="small"
                align="center"
                start-placeholder="开始时间"
                range-separator="至"
                end-placeholder="结束时间"
                value-format='yyyy-MM-dd HH:mm:ss'
                :default-time="['06:00:00', '10:00:00']"
                :picker-options="pickerOptions1" />
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handlePlayRecord">确 定</el-button>
            <el-button @click="showRecordConfig = false">取 消</el-button>
          </span>
        </el-dialog>

      </el-main>
    </el-container>

  </el-container>
</template>

<script>
import {selectCameraPage, cameraTreeList, liveList, recordList, selectCameraTree} from "@/api/sanit";
import ezuikit from 'ezuikit-js';

export default {
  data() {

    let _minTime = null
    let _maxTime = null

    return {
      pickerOptions1: {
        // shortcuts: [{
        //   text: '今天',
        //   onClick(picker) {
        //     picker.$emit('pick', new Date());
        //   }
        // }, {
        //   text: '昨天',
        //   onClick(picker) {
        //     const date = new Date();
        //     date.setTime(date.getTime() - 3600 * 1000 * 24);
        //     picker.$emit('pick', date);
        //   }
        // }, {
        //   text: '前天',
        //   onClick(picker) {
        //     const date = new Date();
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
        //     picker.$emit('pick', date);
        //   }
        // }],
        onPick(time){
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let timeRange = 6*24*60*60*1000 // 6天
            _minTime = time.minDate.getTime() - timeRange // 最小时间
            _maxTime = time.minDate.getTime() + timeRange // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            _minTime = _maxTime = null
          }
        },
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if(_minTime && _maxTime){
            return time.getTime() < _minTime || time.getTime() > _maxTime
          }
        }
      },

      /*分页参数*/
      pageIndex: 1,
      pageSize: 6,
      totalPage: 0,

      treeList: [],
      cameraList: [],
      cameraDataList: [],

      showAll: true,
      /*ezvizPlay参数*/
      ezvizPlayMap: {},
      /*ezviz回放参数*/
      datePickMap: {},
      ezvizRecordConfig: {
        deviceSerial: '',
        datePick: [(new Date().format("yyyy-MM-dd") + ' 06:00:00').parseDateTime(), (new Date().format("yyyy-MM-dd") + ' 10:00:00').parseDateTime()],
      },
      showRecordConfig: false,
      accessToken: null,
      width: '390px',
      height: '360px',
      isMobile: false,
      menuShow: true,
      streetId: 1,
      streetName: "全部监控"
    }
  },
  created() {
    let _streetId = this.getQueryString('streetId');
    if(_streetId != null && _streetId.length > 0) {
      this.streetId = _streetId;
    }
    let _this = this;
    //this.height = Math.min((document.documentElement.clientWidth/4 - 120), 360) + 'px';
    console.info('window.screen.availWidth', window.screen.availWidth);

    if(window.screen.availWidth <= 480) {
      this.width = document.documentElement.clientWidth - 45;
      this.height = this.width * (360.0/390.0);

      _this.isMobile = true;
      _this.menuShow = true;
      setTimeout(function (){
        _this.menuShow = false;
      },1000);
    }
    console.info('width,height', this.width, this.height);
    this.init();
  },
  methods: {
    getQueryString (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null){
        return unescape(r[2]);
      }
      return null;
    },
    // 每页数
    // sizeChange:function (val) {
    //   this.stopVideoAll();
    //   this.pageSize = val;
    //   this.pageIndex = 1;
    //   this.init();
    // },
    // // 当前页
    // currentChange:function (val) {
    //   this.stopVideoAll();
    //   this.pageIndex = val;
    //   this.init();
    // },

    init: function () {
      this.cameraDataList = [];

      selectCameraTree({ streetId: this.streetId, status: 1}).then(rsp => {
        console.info('selectCameraTree', rsp.headers, rsp.data.data);

        this.streetName = decodeURI(rsp.headers['street'])+ '全部监控';
        this.accessToken = rsp.headers['accesstoken'];
        console.info('street', rsp.headers['street'], this.streetName);
        this.treeList = [];
        this.cameraDataList = [];

        for(let stationName in rsp.data.data) {
          if('Unknow' === stationName) continue;

          let children = rsp.data.data[stationName];
          children.forEach(a=>a['stationName'] = stationName);
          children.forEach(a=>a['playerVisible'] = false);
          children.forEach(a=>a['liveUrl']='ezopen://open.ys7.com/'+a['deviceSerial']+'/1.live');

          this.treeList.push({
            stationName: stationName,
            children: children
          });

          this.cameraDataList.push.apply(this.cameraDataList, children);
        }

        console.info('treeList', this.treeList);
        console.info('cameraDataList', this.cameraDataList);

        setTimeout(() => {
          this.handleStationClick(null);
        }, 100);

      }).catch(e=>{
        this.$notify.error({title: '错误', showClose: true, message: '加载列表失败：' + e.message});
      });
    },
    bindListView: function (dataList) {

      let _this = this;
      $(".video-container").empty();

      for(let deviceSerial in this.ezvizPlayMap) {
        _this.showPlayer( deviceSerial, false);
      }

      dataList.forEach(e => {
        e['playerVisible'] = false;
      });
    },

    playLiveAll () {
      console.log('playLiveAll');
      this.cameraDataList.forEach(e => {
        this.playLive(e);
      })
    },
    stopVideoAll () {
      console.log('stopVideoAll');
      this.cameraDataList.forEach(e => {
        this.stopVideo(e.deviceSerial);
      })
    },
    playLive (item) {
      let deviceSerial = item.deviceSerial;
      let url = item.liveUrl;

      console.log('playLive', deviceSerial, url);

      this.playVideoView(item, url);
    },
    stopVideo: function (deviceSerial, release) {
      console.log('stopVideo', deviceSerial, release);

      if(this.ezvizPlayMap[deviceSerial]){
        try {
          this.ezvizPlayMap[deviceSerial].stop();
        } catch (error) {
          console.log('stopVideo', error);
        }
      }

      if(release) {
        $('#video-container-' + deviceSerial).empty();
        if(this.ezvizPlayMap[deviceSerial]) {
          delete this.ezvizPlayMap[deviceSerial];
        }
      }

      this.showPlayer( deviceSerial, false);
    },
    playRecord: function (item) {
      //回放

      let deviceSerial = item.deviceSerial;
      console.log('playRecord', deviceSerial);

      if(!this.datePickMap[deviceSerial]) {
        this.datePickMap[deviceSerial] = [(new Date().format("yyyy-MM-dd") + ' 06:00:00').parseDateTime(), (new Date().format("yyyy-MM-dd") + ' 10:00:00').parseDateTime()];
      }

      //配置时间
      this.ezvizRecordConfig.item = item;
      this.ezvizRecordConfig.deviceName = item.deviceName.appendDeviceSerial(deviceSerial);
      this.ezvizRecordConfig.deviceSerial = deviceSerial;
      this.ezvizRecordConfig.datePick = this.datePickMap[deviceSerial];

      console.log('playRecord', deviceSerial, this.ezvizRecordConfig.datePick);

      //弹出日期选择框
      this.showRecordConfig = true;
    },

    handlePlayRecord(){
      let _this = this;
      let _item = this.ezvizRecordConfig.item;
      let deviceSerial = this.ezvizRecordConfig.deviceSerial;

      this.ezvizRecordConfig.datePick = [
        this.ezvizRecordConfig.datePick[0].parseDateTime ? this.ezvizRecordConfig.datePick[0].parseDateTime() : this.ezvizRecordConfig.datePick[0],
        this.ezvizRecordConfig.datePick[1].parseDateTime ? this.ezvizRecordConfig.datePick[1].parseDateTime() : this.ezvizRecordConfig.datePick[1],
      ];

      let datePick = this.ezvizRecordConfig.datePick;

      console.log('handlePlayRecord', deviceSerial, datePick);

      let start = datePick[0].format('yyyyMMddHHmmss');
      let end = datePick[1].format('yyyyMMddHHmmss');

      let args = {
          accessToken: this.accessToken, //String 访问令牌 Y
          deviceSerial: deviceSerial, // String 设备序列号,存在英文字母的设备序列号，字母需为大写 Y
          channelNo: 1, // int 通道号，非必选，默认为1 N
          startTime: datePick[0].getTime(),// long 起始时间，时间格式为：1378345128000。非必选，默认为当天0点 N
          endTime: datePick[1].getTime(),// long 结束时间，时间格式为：1378345128000。非必选，默认为当前时间 N
          recType: 2 // int 回放源，0-系统自动选择，1-云存储，2-本地录像。非必选，默认为0 N
      };

      let params = [];
      for(let p in args) {
        params.push(p + '=' + args[p]);
      }

      console.info('recordList', args, params);

      recordList(params.join('&')).then(rsp=>{
        console.info('recordList', args, rsp.data);

        let files = [];
        if(rsp.data.code != '200') {
          this.$notify.error({title: '错误', showClose: true, message: rsp.data.msg});
        }else if(rsp.data.data == null || rsp.data.data.length == 0){
          this.$notify.error({title: '错误', showClose: true, message: '设备['+deviceSerial+']没找到录像文件' });
        } else {
          rsp.data.data.forEach(a=>{
            //fileType int 文件类型
            // 0：目录,1：视频文件,2：图片文件,3：音频文件
            if(a.fileType === 1) {
              files.push(a);
            }
          });
          console.info('recordList', args, files);
        }
      }).catch(e=>{
        console.error('recordList', args, e);
        this.$notify.error({title: '错误', showClose: true, message: '设备['+deviceSerial+']查询录像文件失败：' + e.message });
      });

      {
        //回放地址（本地录像）
        //url = 'ezopen://open.ys7.com/F44369404/1.rec?begin=20220316100000&end=20220316105959'
        let url = 'ezopen://open.ys7.com/'+deviceSerial+'/1.rec?begin='+start+'&end=' + end;

        this.stopVideo(deviceSerial, false);
        this.playVideoView(_item, url);
        console.log('handlePlayRecord', deviceSerial, url);

        _this.showRecordConfig = false;

        _this.$notify.info({title: '提示', showClose: true, message: '设备[' + deviceSerial + ']已切换到回放'});
      }

    },

    showPlayer(deviceSerial,show) {
      console.info('showPlayer', deviceSerial, show);

      this.cameraList.forEach(a=>{
        if(a['deviceSerial'] === deviceSerial) {
          a['playerVisible'] = show;
        }
      });
    },


    handleMenuClick() {
      this.menuShow = !this.menuShow;
    },

    handleStationClick: function (station) {
      let _this = this;
      this.menuShow = false;
      this.showAll = station == null;

      if(this.cameraList.length > 0){
        this.cameraList.forEach(e => _this.stopVideo(e.deviceSerial, true));
      }

      $(".video-container").empty();
      this.cameraList = [];

      if(station == null) {
        //all
        this.cameraList.push.apply(this.cameraList, this.cameraDataList);
      }else{
        this.cameraList = station.children;
      }

      setTimeout(() => {
        this.bindListView(this.cameraList);
      }, 100);
    },

    playVideoView (item, url) {
      let deviceSerial = item.deviceSerial;

      console.info('playVideoView.dataList',this.dataList);

      this.showPlayer( deviceSerial, true);

      let _url = url || item.liveUrl;

      console.info('playVideoView',deviceSerial, _url );

      var player = this.ezvizPlayMap[deviceSerial];
      console.info('playVideoView','11');
      if(player) {
        try {
          player.stop();
        }
        catch (ex) {
          console.error('playVideoView.stop', deviceSerial, ex);
        }
        try {
          player.play(_url);
        }
        catch (ex) {
          console.error('playVideoView.play', deviceSerial, ex);
        }
        return;
      }

      console.info('length', $('#video-container-' + deviceSerial).length);

      console.info('playVideoView','22');
      player = new ezuikit.EZUIKitPlayer({
        //自动播放
        autoplay: true,
        //视频播放包裹元素
        id: 'video-container-' + deviceSerial,
        //萤石token
        accessToken: this.accessToken,
        url: _url,
        template: 'security', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        // header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        // header: ['capturePicture', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
        header:['zoom'],
        plugin: ['talk'], // 加载插件，talk-对讲 //'talk'
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        footer: ['talk', 'hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖 //'talk', 'hd', 'fullScreen'
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        //宽度以及高度
        //width: 950,
        width: this.width,
        height: this.height,
        scrolling: 'no'
      });
      console.info('playVideoView','33');

      this.ezvizPlayMap[deviceSerial] = player;

      console.info('playVideoView','44');
      console.info('playVideoView', player);

    },
  }
};
</script>

<style>
.el-table td, .el-table th.is-leaf {
  border-color: #EBEEF5 !important;
  border-bottom: 1px solid #EBEEF5;
}

.avue-dialog__top{
  display: block;
  padding: 10px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  text-align: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  color: #0f1014;
}

.el-pagination.is-background .el-pager li:not(.disabled).active{
  color: #0f1014;
}

.el-card__header {
  padding: 10px;
}

.el-card__body {
  padding: 0px;
}
.el-dialog__wrapper {
  z-index: 1000;
}
.el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:20%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
  width: 500px;
  background: #fff;
}
.el-dialog .el-dialog__body{
  padding: 0px 20px;
  flex:1;
  overflow: auto;
}
.el-range-editor--small.el-input__inner {
  width: 100%;
}
.el-range-editor--small .el-range-input {
  background-color: #fff !important;
}

.el-picker-panel__body input.el-input__inner {
  background-color: #fff !important;
}

.video-container {
  padding: 0px;
  min-height: 360px;
}

.video-container-holder i.el-icon-video-play {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
  min-height: 230px;
  padding-top: 130px;
}
.video-container-holder i.el-icon-video-play::before {
  font-size: 6em;
  cursor: pointer;
}

@media screen and ( max-width: 480px ) {
  .el-aside{
    position: fixed;
    z-index: 10000;
    height: 100%;
  }

  #app .el-container{
    width: 100%;
  }

  #app .el-main{
    width: 100%;
  }

  .el-menu {
    border-bottom: solid 1px #e6e6e6;
  }

  .el-menu-item, .el-submenu__title {
    height: 36px;
    line-height: 36px;
  }

  .el-range-editor--small{
    width: 100%;
  }
}

</style>
