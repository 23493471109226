import {path} from '@/config';
import request from '../axios';


export const vehicleList = (params) => request({
    url: path + '/v1/cleaner/selectList',
    method: 'get',
    params: params
});

export const deviceCurrent = (params) => request({
    url: path + '/v1/cleaner/deviceCurrent2',
    method: 'get',
    params: params
});

export const loucs = (params) => request({
    url: path + '/v1/beiQianGps/loucs2',
    method: 'get',
    params: params
});

