<template>
  <el-container style="background-color: #FFFFFF; height: 100%; border: 1px solid #eee">
    <el-main style="padding: 0px;">
      <div class="search-box" style="left: 10px; top: 10px;">
        <el-card class="box-card">
          <el-row>
            <el-tree :data="vehicleTreeList" :props="defaultProps" @node-click="handleNodeClick" default-expand-all :expand-on-click-node="false">
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <div v-if="data.top">{{ node.label }}</div>
                <template v-else>
                  <div slot="title" v-if="data.delFlag === '0'" style="display: flex; width: 100%;">
                    <div style="color: green; flex: 1;">{{ node.label }}[在线]</div>
                    <div style=" flex: 1; padding-left: 1em; "><a style="font-size: 1em;" target="_blank" @click.stop="carPlayClick" :href="'/api/v1/vehicle2/play/' + vehicleMap[node.label]">查看监控</a></div>
                  </div>
                  <div slot="title" style="display: flex;" v-else-if="data.delFlag === '1'">
                    <div style="color: red;  flex: 1;">{{ node.label }}[离线]</div>
                    <div style=" flex: 1; padding-left: 1em; "><a style="font-size: 1em;" target="_blank" @click.stop="carPlayClick" :href="'/api/v1/vehicle2/play/' + vehicleMap[node.label]">查看监控</a></div>
                  </div>
                </template>
              </div>
            </el-tree>

            <el-row>
              <el-col :span="21"><el-slider v-model="speed" step="1000" max="10000" label="米/小时" show-stops></el-slider></el-col>
              <el-col :span="3"><span style="line-height: 35px">米/小时</span></el-col>
            </el-row>

            <el-date-picker
                v-model="trajectoryTime"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                :picker-options="pickerOptions"
                @change="trajectoryTimeChange"
                :default-time="['10:00:00']">
            </el-date-picker>
            <el-button type="success" @click="dataResetReplay">轨迹重放</el-button>
          </el-row>
        </el-card>
      </div>

      <div id="map__container" :style="node" class='container' style="height: 100%;"></div>
    </el-main>
  </el-container>
</template>

<script>
import {vehicleList,deviceCurrent, loucs} from "@/api/sanit";

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
      },
      vehicleMap: {},
      vehicleList: [],
      vehicleTreeList: [],
      defaultProps: {
        children: 'children',
        label: 'vehicleLicence'
      },
      showFlag: false,
      node: {
        //width: document.documentElement.clientWidth + 'px',
        height: (document.documentElement.clientHeight - 80) + 'px'
      },
      //运行轨迹经纬度坐标
      linePath: [],
      marker: [],
      zoom: 16,
      speed: 5000,
      //车牌号
      vehicleLicence: undefined,
      vehicleSerialNumber: undefined,
      trajectoryTime: '',
      areaId: 2
    }
  },
  created() {
    let _areaId = this.getQueryString('areaId');
    if(_areaId != null && _areaId.length > 0) {
      this.areaId = _areaId;
    }
    vehicleList({areaId: this.areaId}).then(result => {
      this.vehicleList = result.data.data;
      console.info('vehicleList', this.vehicleList);
      for(let i = 0; i<this.vehicleList.length; i++) {
        let v = this.vehicleList[i];
        this.vehicleMap[v.vehicleLicence] = v.id;
      }

      this.vehicleTreeList = [{
        "vehicleLicence": '福建广源再生['+this.vehicleList.length+']',
        "children": this.vehicleList,
        'top': true
      }];
      this.init();
    });
  },
  methods: {
    carPlayClick(e) {
      console.info('carPlayClick', e);
      return false;
    },
    getQueryString (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null){
        return unescape(r[2]);
      }
      return null;
    },
    handleNodeClick: function (e){
      if(e.top) {
        this.init();
      } else {
        this.handleClick(e.vehicleLicence,e.vehicleSerialNumber)
      }
    },
    init: function () {
      let $this = this;
      $this.map = new AMap.Map('map__container', {
        zoom: this.zoom,//级别
        center: [119.2964,26.074228],//中心点坐标，福州市政府
        resizeEnable: true,
      });
      var center = $this.map.getCenter();
      console.info('center', center);
      deviceCurrent({areaId: this.areaId}).then(response => {
        const data = response.data.data;
        console.info('deviceCurrent', data);

        if(data == undefined || data == null){
          this.$notify.error({title: '提示',showClose: true, message: '暂无数据'});
          return;
        }
        this.showFlag = false;

        this.vehicleList.forEach(e => {
          //loc->[lon, lat]
          const loc = data[e.vehicleLicence];
          console.info('loc', e.vehicleLicence, loc);
          if(loc === undefined || loc === null){
            return;
          }

          new AMap.Marker({
            map: $this.map,
            icon: new AMap.Icon({
              size: new AMap.Size(30, 40),
              image: './img/car_90.png',
              imageSize: new AMap.Size(30, 30),
              imageOffset: new AMap.Pixel(0, 17),
            }),
            label: {
              content: "<div class='info'>"+e.vehicleLicence+"</div>", //设置文本标注内容
            },
            autoRotation: true,
            center: center,
            position: loc,
            title: '车牌号:'+e.vehicleLicence
          });
        });

        // 第一个参数为空，表明用图上所有覆盖物 setFitview
        // 第二个参数为false, 非立即执行
        // 第三个参数设置上左下右的空白
        $this.map.setFitView(null, false, [0, 0, 0, 0]);
      });
    },

    loadTrackMap: function () {
      /**
       * 绘制移动轨迹路线
       */
      const passedPolyline = new AMap.Polyline({
        map: this.map,
        path: [],
        strokeColor: 'red',
        strokeWeight: 6,
      });

      /**
       * 添加用于移动的图标
       */
      this.marker = new AMap.Marker({
        map: this.map,
        title: '车牌号:'+this.vehicleLicence,
        center: this.linePath[0],//中心点坐标
        icon: new AMap.Icon({
          size: new AMap.Size(30, 40),
          image: './img/car_90.png',
          imageSize: new AMap.Size(30, 30),
          imageOffset: new AMap.Pixel(0, 17),
        }),
        label: {
          content: "<div class='info'>"+this.vehicleLicence+"</div>", //设置文本标注内容
        },
        autoRotation: true
      });

      new AMap.Marker({
        map: this.map,
        icon: './img/start.png',  //标记的图标
        position: this.linePath[0],  //标记的坐标
      });

      this.marker.on('moving', function (e) {
        passedPolyline.setPath(e.passedPath);
      });

      this.marker.moveAlong(this.linePath, this.speed);
    },
    trajectoryTimeChange: function () {
      this.handleClick(this.vehicleLicence,this.vehicleSerialNumber);
    },
    handleClick: function (vehicleLicence,vehicleSerialNumber) {
      let $this = this;

      this.showFlag = true;
      this.vehicleLicence = vehicleLicence;
      this.vehicleSerialNumber = vehicleSerialNumber;

      if(vehicleSerialNumber === undefined || vehicleSerialNumber === null ||vehicleSerialNumber === '0'){
        this.$notify.error({title: '提示',showClose: true, message: '未绑定设备序列号'});
        return;
      }

      let searchForm = {vehicleSerialNumber: vehicleSerialNumber};

      if(this.trajectoryTime !== undefined && this.trajectoryTime !== ''){
        if(this.trajectoryTime[0] === this.trajectoryTime[1]){
          this.$notify.error({title: '提示',showClose: true, message: '查询的开始日期与结束日期不能相同'});
          return;
        }

        searchForm = {
          vehicleSerialNumber: vehicleSerialNumber,
          beginTime: this.trajectoryTime[0],
          endTime: this.trajectoryTime[1],
        }
      }

      //this.$notify.success({title: '提示', showClose: true, message: name});
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      loucs(searchForm).then(result =>{
        loading.close();

        this.linePath = result.data.data;
        console.info('this.linePath', this.linePath);

        if(this.linePath === null || this.linePath === undefined || this.linePath.length === 0){
          this.$notify.error({title: '提示', showClose: true, message: '10分钟内暂无路线图'});
          return;
        }

        this.visible = true;
        this.canSubmit = true;

        this.$nextTick(() => {
          // 清除地图覆盖物
          $this.map.clearMap();
          let _center = this.linePath[0];
          if(this.linePath.length > 4) {
            let min_lon = _center[0], max_lon = _center[0];
            let min_lat = _center[1], max_lat = _center[1];

            this.linePath.forEach(a=>{
              if(a[0]<min_lon) {
                min_lon = a[0];
              }
              if(a[0]>max_lon) {
                max_lon = a[0];
              }

              if(a[1]<min_lat) {
                min_lat = a[1];
              }
              if(a[1]>max_lat) {
                max_lat = a[1];
              }
            });

            _center = [(min_lon + max_lon)/2, (min_lat + max_lat)/2];
          }
          console.info('_center', _center);

          $this.map = new AMap.Map('map__container', {
            zoom: 14,//级别
            center: _center,//中心点坐标
            resizeEnable: true,
          });

          this.loadTrackMap();
        });
      }).catch(function() {
        loading.close();
      });
    },
    dataResetReplay: function () {
      this.$nextTick(() => {
        this.zoom = this.map.getZoom();
        this.map = undefined;
        this.map = new AMap.Map('map__container', {
          zoom: this.zoom,//级别
          center: this.linePath[0],//中心点坐标
          resizeEnable: true,
        });

        this.loadTrackMap();
      })
    },
  }
};
</script>

<style>
.search-box {
  width: auto;
  position: absolute;
  z-index: 5;
  left: 30px;
  top: 30px;
  height: 30px;
}

.search-box input {
  border: 1px solid #30ccc1;
  padding: 0 8px;
  outline: none;
}

.search-box button, .search-box button:hover {
  background: #67c23a;
  border: 1px solid #67c23a;
  color: #fff;
  outline: none;
}

.el-table td, .el-table th.is-leaf {
  border-color: #EBEEF5 !important;
  border-bottom: 1px solid #EBEEF5;
}

.el-range-input, .el-input__inner {
  background-color: #FFFFFF !important;
  border-color: #EBEEF5 !important;
}

.el-input-group__prepend, .el-input-group__append{
  background-color: #EBEEF5 !important;
  border-color: #EBEEF5 !important;
}
</style>
