import {path} from '@/config';
import request from '../axios';

export const stationList = (params) => request({
    url: path + '/v1/postStation/stationList',
    method: 'get',
    params: params
});
export const stationPositionList = (params) => request({
    url: path + '/v1/postStation/positonList',
    method: 'get',
    params: params
});
export const vehicleList = (params) => request({
    url: path + '/v1/vehicle/selectList',
    method: 'get',
    params: params
});

export const deviceCurrent = (params) => request({
    url: path + '/v1/beiQianGps/deviceCurrent2',
    method: 'get',
    params: params
});

export const loucs = (params) => request({
    url: path + '/v1/beiQianGps/loucs2',
    method: 'get',
    params: params
});

export const cameraTreeList = () => request({
    url: path + '/v1/postStation/treeList',
    method: 'get',
});

export const selectCameraPage = (params) => request({
    url: path + '/v1/camera/selectPage',
    method: 'get',
    params: params
});

export const liveList = (query) => request({
    url: path + '/v1/fluorite/liveList',
    method: 'get',
    params: query
});

export const selectCameraTree = (params) => request({
    url: path + '/v1/camera/selectTree',
    method: 'get',
    params: params
});
export const recordList = (params)=>request({
    url: "https://open.ys7.com/api/lapp/video/by/time",
    method: 'post',
    //params: params,
    contentType: "application/x-www-form-urlencoded",
    data: params
});
